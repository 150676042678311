import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { AuthenticationModel } from '@models'
import AuthenticationApi from '@utils/authentication-api'

type Props = {
  showBrand: boolean
}

const useInitialConfig = ({ showBrand }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [authentication, setAuthentication] = useState<AuthenticationModel>()

  const navigate = useNavigate()
  const { id } = useParams()
  const checkedId = id ?? ''

  const getAuthentication = async () => {
    setIsLoading(true)
    try {
      const responseAuthentication = await AuthenticationApi.get(checkedId)

      if (!responseAuthentication) {
        navigate('/error', { state: { showBrand } })
        return
      }

      setAuthentication(responseAuthentication)
    } catch (error) {
      navigate('/error', { state: { showBrand } })
      Sentry.captureException(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getAuthentication()
  }, [])

  useEffect(() => {
    if (authentication && import.meta.env.PROD) {
      Sentry.configureScope((scope) => {
        scope.setUser({ email: authentication.payerEmail })
        scope.setTags({
          ...authentication
        })
      })
    }
  }, [authentication])

  return { isLoading, authentication }
}

export default useInitialConfig
