import * as Sentry from '@sentry/react'
import { AuthenticationModel } from '@models'
import { AuthenticationStatus } from '@enums'
import ApiService from './payer-auth-api'
import { getAuthenticationStatusString } from './authentication-helpers'

class AuthenticationApi {
  static async get(id: string): Promise<AuthenticationModel | undefined> {
    try {
      const authentication = await ApiService.get<AuthenticationModel>(
        `info/${id}`
      )
      return authentication
    } catch (error) {
      Sentry.captureException(error)
      return undefined
    }
  }

  static emitAuthenticationResult(authentication?: AuthenticationModel) {
    const dataMessage = {
      MessageType:
        authentication?.status === AuthenticationStatus.SUCCESS
          ? 'authentication.complete'
          : 'authentication.failed',
      Status: getAuthenticationStatusString(authentication?.status),
      AuthenticationId: authentication?.authenticationId,
      OrderId: authentication?.orderId,
      OrderAmount: authentication?.orderAmount
    }

    window.parent.postMessage(JSON.stringify(dataMessage), '*')
  }
}

export default AuthenticationApi
